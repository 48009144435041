var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"side-navigation-menu",class:{ 'is-open': _vm.isCollapse }},[_c('div',{staticStyle:{"max-height":"90vh !important"}},[_c('el-scrollbar',{attrs:{"wrap-style":"max-height: 90vh;"}},[_c('el-menu',{ref:"sidebarMenu",staticStyle:{"width":"220px !important"},attrs:{"background-color":"#fff","text-color":"#303133","active-text-color":"#F754A2","collapse":!_vm.isCollapse}},[_vm._l((_vm.sideBarItem),function(sideBarItem){return [(sideBarItem.children && sideBarItem.children.length)?[_c('el-submenu',{key:sideBarItem._id,attrs:{"index":sideBarItem._id}},[_c('template',{slot:"title"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"circle-icon-app",style:(`background: ${_vm.iconBgColor} !important;`)},[_c('img',{staticStyle:{"height":"22px","width":"22px","margin-bottom":"18px !important"},attrs:{"src":require('@/assets/img/icons/' +
                          _vm.getParentPath(sideBarItem.icon_category) +
                          '/' +
                          sideBarItem.icon),"alt":"menu","width":"26"}})]),_c('span',{attrs:{"title":sideBarItem.title.length > 15 ? sideBarItem.title : ''}},[_vm._v(_vm._s(_vm._f("truncate")(sideBarItem.title,15, "...")))])])]),_c('el-menu-item-group',[_vm._l((sideBarItem.children),function(sideBarSubItem){return [(sideBarSubItem.type == 'Child')?[_c('el-menu-item',{key:sideBarSubItem._id,attrs:{"index":sideBarSubItem._id},on:{"click":function($event){return _vm.routeClick(sideBarSubItem, true)}}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"circle-icon-app",style:(`background: ${_vm.iconBgColor} !important;`)},[_c('img',{staticStyle:{"height":"22px","width":"22px","margin-bottom":"18px !important"},attrs:{"src":require('@/assets/img/icons/' +
                                _vm.getParentPath(sideBarSubItem.icon_category) +
                                '/' +
                                sideBarSubItem.icon),"alt":"menu","width":"26"}})]),_c('span',{attrs:{"title":sideBarSubItem.title.length > 11
                              ? sideBarSubItem.title
                              : ''}},[_vm._v(" "+_vm._s(_vm._f("truncate")(sideBarSubItem.title,11, "..."))+" ")])])])]:_vm._e(),(sideBarSubItem.type == 'SubParent')?[_c('el-submenu',{key:sideBarSubItem._id,attrs:{"index":sideBarSubItem._id}},[_c('template',{slot:"title"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"circle-icon-app",style:(`background: ${_vm.iconBgColor} !important;`)},[_c('img',{staticStyle:{"height":"22px","width":"22px","margin-bottom":"18px !important"},attrs:{"src":require('@/assets/img/icons/' +
                                  _vm.getParentPath(
                                    sideBarSubItem.icon_category
                                  ) +
                                  '/' +
                                  sideBarSubItem.icon),"alt":"menu","width":"26"}})]),_c('span',{attrs:{"title":sideBarSubItem.title.length > 10
                                ? sideBarSubItem.title
                                : ''}},[_vm._v(_vm._s(_vm._f("truncate")(sideBarSubItem.title,10, "...")))])])]),_c('el-menu-item-group',[_vm._l((sideBarSubItem.children),function(sideBarSubSubItem){return [(sideBarSubSubItem.type == 'Child')?[_c('el-menu-item',{key:sideBarSubSubItem._id,attrs:{"index":sideBarSubSubItem._id},on:{"click":function($event){return _vm.routeClick(sideBarSubSubItem, true)}}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"circle-icon-app",style:(`background: ${_vm.iconBgColor} !important;`)},[_c('img',{staticStyle:{"height":"22px","width":"22px","margin-bottom":"18px !important"},attrs:{"src":require('@/assets/img/icons/' +
                                        _vm.getParentPath(
                                          sideBarSubItem.icon_category
                                        ) +
                                        '/' +
                                        sideBarSubItem.icon),"alt":"menu","width":"26"}})]),_c('span',{attrs:{"title":sideBarSubSubItem.title.length > 8
                                      ? sideBarSubSubItem.title
                                      : ''}},[_vm._v(" "+_vm._s(_vm._f("truncate")(sideBarSubSubItem.title,8, "..."))+" ")])])])]:_vm._e()]})],2)],2)]:_vm._e()]})],2)],2)]:[_c('el-menu-item',{key:sideBarItem._id,attrs:{"index":sideBarItem._id},on:{"click":function($event){return _vm.routeClick(sideBarItem, false)}}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"circle-icon-app",style:(`background: ${_vm.iconBgColor} !important;`)},[_c('img',{staticStyle:{"height":"22px","width":"22px","margin-bottom":"18px !important"},attrs:{"src":require('@/assets/img/icons/' +
                        _vm.getParentPath(sideBarItem.icon_category) +
                        '/' +
                        sideBarItem.icon),"alt":"menu","width":"26"}})]),_c('span',{attrs:{"title":sideBarItem.title.length > 16 ? sideBarItem.title : ''}},[_vm._v(_vm._s(_vm._f("truncate")(sideBarItem.title,16, "...")))])])])]]})],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }