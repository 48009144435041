<template>
  <section class="side-navigation-menu" :class="{ 'is-open': isCollapse }">
    <div style="max-height: 90vh !important">
      <el-scrollbar wrap-style="max-height: 90vh;">
        <el-menu
          background-color="#fff"
          text-color="#303133"
          active-text-color="#F754A2"
          ref="sidebarMenu"
          :collapse="!isCollapse"
          style="width: 220px !important"
        >
          <template v-for="sideBarItem in sideBarItem">
            <template
              v-if="sideBarItem.children && sideBarItem.children.length"
            >
              <el-submenu :key="sideBarItem._id" :index="sideBarItem._id">
                <template slot="title">
                  <!-- if we're using el-tooltip based on title length condition, it is taking lengthy code. 
                  Hence, I have used span tag with title attribute -->
                  <!-- <el-tooltip effect="dark" :content="sideBarItem.title" placement="right">
                  </el-tooltip> -->
                  <div class="d-flex">
                    <div
                      class="circle-icon-app"
                      :style="`background: ${iconBgColor} !important;`"
                    >
                      <img
                        :src="
                          require('@/assets/img/icons/' +
                            getParentPath(sideBarItem.icon_category) +
                            '/' +
                            sideBarItem.icon)
                        "
                        alt="menu"
                        width="26"
                        style="
                          height: 22px;
                          width: 22px;
                          margin-bottom: 18px !important;
                        "
                      />
                    </div>
                    <span
                      :title="
                        sideBarItem.title.length > 15 ? sideBarItem.title : ''
                      "
                      >{{ sideBarItem.title | truncate(15, "...") }}</span
                    >
                  </div>
                </template>

                <el-menu-item-group>
                  <template v-for="sideBarSubItem in sideBarItem.children">
                    <template v-if="sideBarSubItem.type == 'Child'">
                      <el-menu-item
                        :key="sideBarSubItem._id"
                        :index="sideBarSubItem._id"
                        @click="routeClick(sideBarSubItem, true)"
                      >
                        <!-- <el-tooltip effect="dark" :content="sideBarSubItem.title" placement="right">
                      </el-tooltip> -->
                        <div class="d-flex">
                          <div
                            class="circle-icon-app"
                            :style="`background: ${iconBgColor} !important;`"
                          >
                            <img
                              :src="
                                require('@/assets/img/icons/' +
                                  getParentPath(sideBarSubItem.icon_category) +
                                  '/' +
                                  sideBarSubItem.icon)
                              "
                              alt="menu"
                              width="26"
                              style="
                                height: 22px;
                                width: 22px;
                                margin-bottom: 18px !important;
                              "
                            />
                          </div>
                          <span
                            :title="
                              sideBarSubItem.title.length > 11
                                ? sideBarSubItem.title
                                : ''
                            "
                          >
                            {{ sideBarSubItem.title | truncate(11, "...") }}
                          </span>
                        </div>
                      </el-menu-item>
                    </template>
                    <template v-if="sideBarSubItem.type == 'SubParent'">
                      <el-submenu
                        :key="sideBarSubItem._id"
                        :index="sideBarSubItem._id"
                      >
                        <template slot="title">
                          <div class="d-flex">
                            <div
                              class="circle-icon-app"
                              :style="`background: ${iconBgColor} !important;`"
                            >
                              <img
                                :src="
                                  require('@/assets/img/icons/' +
                                    getParentPath(
                                      sideBarSubItem.icon_category
                                    ) +
                                    '/' +
                                    sideBarSubItem.icon)
                                "
                                alt="menu"
                                width="26"
                                style="
                                  height: 22px;
                                  width: 22px;
                                  margin-bottom: 18px !important;
                                "
                              />
                            </div>
                            <!-- <span slot="title">{{ sideBarSubItem.title  }}</span> -->
                            <span
                              :title="
                                sideBarSubItem.title.length > 10
                                  ? sideBarSubItem.title
                                  : ''
                              "
                              >{{
                                sideBarSubItem.title | truncate(10, "...")
                              }}</span
                            >
                          </div>
                        </template>
                        <el-menu-item-group>
                          <template
                            v-for="sideBarSubSubItem in sideBarSubItem.children"
                          >
                            <template v-if="sideBarSubSubItem.type == 'Child'">
                              <el-menu-item
                                :key="sideBarSubSubItem._id"
                                :index="sideBarSubSubItem._id"
                                @click="routeClick(sideBarSubSubItem, true)"
                              >
                                <div class="d-flex">
                                  <div
                                    class="circle-icon-app"
                                    :style="`background: ${iconBgColor} !important;`"
                                  >
                                    <img
                                      :src="
                                        require('@/assets/img/icons/' +
                                          getParentPath(
                                            sideBarSubItem.icon_category
                                          ) +
                                          '/' +
                                          sideBarSubItem.icon)
                                      "
                                      alt="menu"
                                      width="26"
                                      style="
                                        height: 22px;
                                        width: 22px;
                                        margin-bottom: 18px !important;
                                      "
                                    />
                                  </div>
                                  <!-- <span
                    
                    >{{ sideBarSubSubItem.title }}</span> -->
                                  <span
                                    :title="
                                      sideBarSubSubItem.title.length > 8
                                        ? sideBarSubSubItem.title
                                        : ''
                                    "
                                  >
                                    {{
                                      sideBarSubSubItem.title
                                        | truncate(8, "...")
                                    }}
                                  </span>
                                </div>
                              </el-menu-item>
                            </template>
                          </template>
                        </el-menu-item-group>
                      </el-submenu>
                    </template>
                  </template>
                </el-menu-item-group>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item
                :key="sideBarItem._id"
                :index="sideBarItem._id"
                @click="routeClick(sideBarItem, false)"
              >
                <!-- <el-tooltip effect="dark" :content="sideBarItem.title" placement="right">
                </el-tooltip> -->
                <div class="d-flex">
                  <div
                    class="circle-icon-app"
                    :style="`background: ${iconBgColor} !important;`"
                  >
                    <img
                      :src="
                        require('@/assets/img/icons/' +
                          getParentPath(sideBarItem.icon_category) +
                          '/' +
                          sideBarItem.icon)
                      "
                      alt="menu"
                      width="26"
                      style="
                        height: 22px;
                        width: 22px;
                        margin-bottom: 18px !important;
                      "
                    />
                  </div>
                  <span
                    :title="
                      sideBarItem.title.length > 16 ? sideBarItem.title : ''
                    "
                    >{{ sideBarItem.title | truncate(16, "...") }}</span
                  >
                </div>
              </el-menu-item>
            </template>
          </template>
        </el-menu>
      </el-scrollbar>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
import NavigationHelper from "@/mixins/navigationHelper";
export default {
  name: "ApplicationUserSidebar",
  mixins: [PermissionsHelper, NavigationHelper],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      companyDataLocal: {},
      activeWorkspace: "",
      sideBarItem: [],
      relationshipEntities: [],
      iconBgColor: "",
      iconColor: "",
      permittedRelatedEntities: [],
      permittedEntities: [],
      planDetails: [],
    };
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsOpen", "getIsCollapse"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getUserMenu",
      "getActiveWorkspace",
      "getUserType",
      "getPermissionList",
      "getPermissions",
    ]),
    ...mapGetters("menuManagementV2", [
      "getMenu",
      "getMenuManagementAddStatus",
      "getMenuManagementUpdateStatus",
      "getEntityMenu",
    ]),
    ...mapGetters("company", ["getContactLoginSlug", "getSingleCompanyLogo"]),
    ...mapGetters("applicationUsers", [
      "getUserVerifyDetails",
      "getUserEmailVerifyDetails",
      "getUserEmailVerificationErrors",
      "getUserSignInVerify",
      "getForgotAndResendVerify",
      "getForgotAndResendVerifyError",
      "getUserDocuments",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveContactType"]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    ...mapGetters("entityRelationships", ["getEntityRelationships"]),
    ...mapGetters("company", ["getContactLoginSlug", "getSingleCompanyLogo"]),

    ...mapGetters("paymentGateway", ["getPaymentsHistory", "getPlansFeatures"]),
    isCollapse: {
      get() {
        return this.getIsOpen;
      },
      set(isCollapse) {
        this.$store.commit("navigationOpen/setIsOpen", isCollapse, {
          root: true,
        });
      },
    },
  },
  async mounted() {
    if (this.getActiveContactType?.contact_type?._id) {
      this.activeWorkspace = this.getActiveContactType.contact_type._id;
    }
    if (this.activeWorkspace) {
      await Promise.all([
        this.$store.dispatch(
          "applicationUsersPermission/fetchPermissionsEntityById",
          { id: this.activeWorkspace }
        ),
        this.$store.dispatch(
          "menuManagementV2/fetchMenuByEntity",
          this.activeWorkspace
        ),
        this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
          entity_id: this.activeWorkspace,
        }),
      ]);
      if (this.getEntityRelationships) {
        this.setRelationshipPermissions();
      }
      if (this.getEntityPermission) {
        this.setPermittedEntities();
      }
      if (this.getEntityMenu) {
        this.setMenuData(this.getEntityMenu);
      } else {
        this.setStandardMenu();
      }
      if (this.getContactLoginSlug) {
        this.iconBgColor = this.getContactLoginSlug.button.background;
        this.iconColor = this.getContactLoginSlug.icon_color;
      }
      this.getSubscriptionHistory();
    }
  },
  methods: {
    async getSubscriptionHistory() {
      try {
        let email = "info@esigns.io";
        await this.$store.dispatch("paymentGateway/fetchSubscriptionInfo", {
          email,
        });
        //this.getPlanExpireDetails();
      } catch (error) {
        console.log("getSubscriptionHistory", error);
      }
    },
    getParentPath(selectedCategory) {
      if (selectedCategory == "default" || !selectedCategory) {
        if (this.iconColor == "White") {
          return "menuApplicationUsers" + "White";
        } else {
          return "menuApplicationUsers";
        }
      } else {
        if (this.iconColor == "White") {
          return selectedCategory + "-" + "White";
        } else {
          return selectedCategory + "-" + "black";
        }
      }
    },
    setMenuData(previousMenuData) {
      let data = previousMenuData
        ? previousMenuData.menu_list.map((e) => {
            if (e.entity_id && e.entity_id._id) {
              e.entity_id = e.entity_id._id;
            }
            if (e.filters && e.filters._id) {
              e.filters = e.filters._id;
            }
            e.children = (e.children || []).map((el) => {
              if (el.entity_id && el.entity_id._id) {
                el.entity_id = el.entity_id._id;
              }
              if (el.filters && el.filters._id) {
                el.filters = el.filters._id;
              }
              el.children = (el.children || []).map((ele) => {
                if (ele.entity_id && ele.entity_id._id) {
                  ele.entity_id = ele.entity_id._id;
                }
                if (ele.filters && ele.filters._id) {
                  ele.filters = ele.filters._id;
                }
                return ele;
              });
              return el;
            });
            return e;
          })
        : [];
      this.sideBarItem = this.filterNotPermittedEntities(data);
      // this.theme = previousMenuData?.has_dark_icons
      //   ? "Dark Icons"
      //   : "Light Icons";
    },
    setPermittedEntities() {
      this.permittedEntities = [];
      this.permittedRelatedEntities = [];
      if (this.getEntityPermission?.other_entities) {
        this.permittedEntities = [
          ...this.permittedEntities,
          ...Object.keys(this.getEntityPermission?.other_entities),
        ];
      }
      if (this.getEntityPermission?.relational_entities) {
        this.permittedRelatedEntities = [
          ...this.permittedRelatedEntities,
          ...Object.keys(this.getEntityPermission?.relational_entities),
        ];
      }
    },
    filterNotPermittedEntities(data) {
      if (this.getEntityPermission?._id) {
        return data.filter((e) => {
          if (e.feature == "entities") {
            if (e.entity_type == "ENTITY") {
              return this.permittedEntities.indexOf(e.entity_id) != -1
                ? true
                : false;
            } else if (e.entity_type == "RELATIONAL_ENTITY") {
              return this.permittedRelatedEntities.indexOf(
                e.relational_entity
              ) != -1
                ? true
                : false;
            }
          }
          return e;
        });
      }
      return data;
    },
    setRelationshipPermissions() {
      this.getEntityRelationships.forEach((el) => {
        if (el?.child_entity_id !== this.activeWorkspace) {
          this.relationshipEntities.push({
            id: el.child_entity_id,
            name: el.child_relation_title,
          });
        } else if (this.activeWorkspace !== el?.parent_entity_id) {
          this.relationshipEntities.push(el.parent_entity_id);
          this.relationshipEntities.push({
            id: el.parent_entity_id,
            name: el.parent_relation_title,
          });
        }
      });
    },
    setStandardMenu() {
      this.sideBarItem.push({
        children: [],
        title: "Dashboard",
        feature: "dashboard",
        category: "",
        icon: "dashboard.svg",
        permission: "",
        isGroup: "",
        type: "Individual",
        _id: (Math.random() + 1).toString(36).substring(7),
      });
      this.sideBarItem.push({
        children: [],
        title: "My Documents",
        feature: "my_documents",
        category: "",
        icon: "File.svg",
        permission: "",
        isGroup: "",
        type: "Individual",
        _id: (Math.random() + 1).toString(36).substring(7),
      });
      this.sideBarItem.push({
        children: [],
        title: "My Workflows",
        feature: "my_workflows",
        category: "",
        type: "Individual",
        icon: "Workflows.svg",
        isGroup: "",
        _id: (Math.random() + 1).toString(36).substring(7),
      });
      this.sideBarItem.push({
        title: "My form builders",
        feature: "my_form_builders",
        category: "",
        parent: "",
        icon: "dashboard.svg",
        children: [],
        permission: "",
        isGroup: "",
        type: "Individual",
        _id: (Math.random() + 1).toString(36).substring(7),
      });
      this.sideBarItem.push({
        children: [],
        title: "My Approval Forms",
        feature: "my_approval_forms",
        category: "",
        icon: "File.svg",
        permission: "",
        isGroup: "",
        type: "Individual",
        _id: (Math.random() + 1).toString(36).substring(7),
      });
      if (this.relationshipEntities && this.relationshipEntities.length) {
        this.relationshipEntities.forEach((e) => {
          this.sideBarItem.push({
            entity_type: "RELATIONAL_ENTITY",
            children: [],
            title: "My " + (e.name ? e.name : "data"),
            feature: "entities",
            category: "",
            icon: "dashboard.svg",
            permission: "",
            isGroup: "",
            relational_entity: e.id,
            type: "Individual",
            _id: (Math.random() + 1).toString(36).substring(7),
          });
        });
      }
      // getEntityMenu
      this.$store.commit(
        "menuManagementV2/setEntityMenu",
        {
          menu_list: this.sideBarItem,
          entity: this.activeWorkspace,
        },
        {
          root: true,
        }
      );
    },
    setActiveContactType() {
      if (this.getActiveContactType?.contact_type?._id) {
        this.activeWorkspace = this.getActiveContactType.contact_type._id;
      }
    },
    async routeClick(data, isChild) {
      await this.$store.commit(
        "menuManagementV2/setCurrentMenu",
        {},
        {
          root: true,
        }
      );
      // const targetRoutes = [
      //   { path: "/ap/dashboard" },
      //   { path: "/ap/documents" },
      //   { path: "/ap/workflows" },
      //   { path: "/ap/formbuilder" },
      // ];

      // // Check if the current route matches any of the target routes
      // const isCurrentRouteTarget = targetRoutes.some(
      //   (route) => route.path === this.$route.path
      // );

      // if (!isCurrentRouteTarget) {
      //   // Navigate to the first target route in the array
      //   this.$router.push(targetRoutes[0]);
      // }
      if (isChild) {
        if (data.entity_type === "NESTED_RELATIONAL_ENTITY" && data?.feature === "entities") {
          let id =
            data.nested_relational_entity &&
            data.nested_relational_entity.includes("#")
              ? data.nested_relational_entity.split("#")[1]
              : data.entity_id && data.entity_id._id
              ? data.entity_id._id
              : data.entity_id;
          // window.location.href = `/ap/entity/${id}/${data._id}?key=${Math.floor(
          //   Math.random() * 100000
          // )}&filter=${
          //   data.filters
          //     ? data.filters._ids
          //       ? data.filters._id
          //       : data.filters
          //     : ""
          // }`;
          this.$router.push({
            path: `/ap/entity/${id}/${data._id}?key=${Math.floor(
              Math.random() * 100000
            )}&filter=${
              data.filters
                ? data.filters._id
                  ? data.filters._id
                  : data.filters
                : ""
            }&customization=${data.customization ? data.customization : ''}`,
          });
        } else if (data.entity_type === "ENTITY" && data?.feature === "entities") {
          let id = data.entity_id && data.entity_id._id
              ? data.entity_id._id
              : data.entity_id;
          // window.location.href = `/ap/entity/${id}/${data._id}?key=${Math.floor(
          //   Math.random() * 100000
          // )}&filter=${
          //   data.filters
          //     ? data.filters._ids
          //       ? data.filters._id
          //       : data.filters
          //     : ""
          // }`;
          this.$router.push({
            path: `/ap/entity/${id}/${data._id}?key=${Math.floor(
              Math.random() * 100000
            )}&filter=${
              data.filters
                ? data.filters._id
                  ? data.filters._id
                  : data.filters
                : ""
            }&customization=${data.customization ? data.customization : ''}`,
          });
        } else if (
          data.feature === "entities" &&
          data.entity_type === "RELATIONAL_ENTITY"
        ) {
          let id =
            data.relational_entity && data.relational_entity._id
              ? data.relational_entity._id
              : data.relational_entity;
          // window.location.href = `/ap/entity/${id}/${data._id}?key=${Math.floor(
          //   Math.random() * 100000
          // )}`;
          this.$router.push({
            path: `/ap/entity/${id}/${data._id}?key=${Math.floor(
              Math.random() * 100000
            )}&filter=${
              data.filters
                ? data.filters._id
                  ? data.filters._id
                  : data.filters
                : ""
            }&customization=${data.customization ? data.customization : ''}`,
          });
        } 
        else if(data.feature === 'page_dashboard' && data.page_dashboard !== this.$route.params.dashboardId){
          this.$router.push(`/ap/page-dashboard/${data.page_dashboard}`)
        } else if(data.feature === 'entity_views'){
          this.$router.push({
            path: `/ap/entity-views/view/${
              data.entityviews_id
            }?routeType=entityviews&key=${Math.floor(Math.random() * 100000)}`,
          });
        }
        else {
          if (data.feature === "dashboard") {
            this.$router.push("/ap/dashboard");
          }
          if (data.feature === "my_documents") {
            this.$router.push("/ap/documents");
          }
          if (data.feature === "my_workflows") {
            this.$router.push("/ap/workflows");
          }
          if (data.feature === "my_form_builders") {
            this.$router.push("/ap/formbuilder");
          }
          if (data.feature === "my_approval_forms") {
            this.$router.push("/ap/approvalforms");
          }
          if (
            data.feature == "form_builder" &&
            data.formbuilder_id &&
            (data.form_builder_code || data?.formbuilder_id?.code)
          ) {
            this.$router.push({
              path: `/ap/fbd/${
                data.form_builder_code || data.formbuilder_id.code
              }`,
              query: {
                key: Math.floor(Math.random() * 100000),
                menuId: data && data._id ? data._id : "",
              },
            });
          }
        }
      } else {
        if (data.feature === "dashboard") {
          this.$router.push("/ap/dashboard");
        }
        if (data.feature === "my_documents") {
          this.$router.push("/ap/documents");
        }
        if (data.feature === "my_workflows") {
          this.$router.push("/ap/workflows");
        }
        if (data.feature === "my_form_builders") {
          this.$router.push("/ap/formbuilder");
        }
        if (data.feature === "my_approval_forms") {
          this.$router.push("/ap/approvalforms");
        }
        if (
          data.feature == "form_builder" &&
          data.formbuilder_id &&
          data.form_builder_code
        ) {
          this.$router.push({
            path: `/ap/fbd/${data.form_builder_code}`,
            query: {
              key: Math.floor(Math.random() * 100000),
              menuId: data && data._id ? data._id : "",
            },
          });
        }
        if (data.entity_type === "NESTED_RELATIONAL_ENTITY" && data?.feature === "entities") {
          let id =
            data.nested_relational_entity &&
            data.nested_relational_entity.includes("#")
              ? data.nested_relational_entity.split("#")[1]
              : data.entity_id && data.entity_id._id
              ? data.entity_id._id
              : data.entity_id;
          // window.location.href = `/ap/entity/${id}/${data._id}?key=${Math.floor(
          //   Math.random() * 100000
          // )}&filter=${
          //   data.filters
          //     ? data.filters._ids
          //       ? data.filters._id
          //       : data.filters
          //     : ""
          // }`;
          this.$router.push({
            path: `/ap/entity/${id}/${data._id}?key=${Math.floor(
              Math.random() * 100000
            )}&filter=${
              data.filters
                ? data.filters._id
                  ? data.filters._id
                  : data.filters
                : ""
            }&customization=${data.customization ? data.customization : ''}`,
          });
        } else if (
          data.feature === "entities" &&
          data.entity_type === "RELATIONAL_ENTITY"
        ) {
          let id =
            data.relational_entity && data.relational_entity._id
              ? data.relational_entity._id
              : data.relational_entity;
          // window.location.href = `/ap/entity/${id}/${data._id}?key=${Math.floor(
          //   Math.random() * 100000
          // )}`;
          this.$router.push({
            path: `/ap/entity/${id}/${data._id}?key=${Math.floor(
              Math.random() * 100000
            )}&filter=${
              data.filters
                ? data.filters._id
                  ? data.filters._id
                  : data.filters
                : ""
            }&customization=${data.customization ? data.customization : ''}`,
          });
        }
        if (data.feature === "entities" && data.entity_type === "ENTITY") {
          let id =
            data.entity_id && data.entity_id._id
              ? data.entity_id._id
              : data.entity_id;
          // window.location.href = `/ap/entity/${id}/${data._id}?key=${Math.floor(
          //   Math.random() * 100000
          // )}`;
          this.$router.push({
            path: `/ap/entity/${id}/${data._id}?key=${Math.floor(
              Math.random() * 100000
            )}&filter=${
              data.filters
                ? data.filters._id
                  ? data.filters._id
                  : data.filters
                : ""
            }&customization=${data.customization ? data.customization : ''}`,
          });
        }
        if(data.feature === 'entity_views'){
          this.$router.push({
            path: `/ap/entity-views/view/${
              data.entityviews_id
            }?routeType=entityviews&key=${Math.floor(Math.random() * 100000)}`,
          });
        }
        if(data.feature === 'page_dashboard' && data.page_dashboard !== this.$route.params.dashboardId){
          this.$router.push(`/ap/page-dashboard/${data.page_dashboard}`)
        }
      }
      await this.$store.commit("menuManagementV2/setCurrentMenu", data, {
        root: true,
      });
    },
  },
  watch: {
    getPaymentsHistory: async function () {
      if (
        this.getPaymentsHistory &&
        this.getPaymentsHistory.planFeatures &&
        this.getPaymentsHistory.planFeatures.length
      ) {
        this.planDetails =
          this.getPaymentsHistory &&
          this.getPaymentsHistory.planFeatures &&
          this.getPaymentsHistory.planFeatures[0];
        if (
          this.planDetails &&
          this.planDetails.plan == 1 &&
          this.planDetails.diff
        ) {
          // this.getPermissionsListByCat();
        } else if (this.planDetails && this.planDetails.diff) {
          // Free Plan permissions
        } else {
          this.$router.push({
            path: `/ap/expire?key=${
              Math.floor(Math.random() * (1000 - 1)) + 100
            }`,
          });
        }
      }
    },
  },

  setColors() {
    if (this.companyDataLocal?.button?.background) {
      this.hexConvertedColor = this.hexToRgb(
        this.companyDataLocal?.button?.background
      );
    }
    if (this.companyDataLocal?.button?.background) {
      this.loginPageColor = this.companyDataLocal.button.background;
    }
  },
};
</script>

<style lang="scss" scoped>
.dashboard-view {
  &.mobile {
    .side-navigation-menu {
      &.is-open {
        transform: translate3d(0px, 0, 0);
      }

      transform: translate3d(-210px, 0, 0);
    }
  }
}

.side-navigation-menu {
  transform: translate3d(0px, 0, 0);
  position: fixed;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  top: 67.5px;
  z-index: 999;
  background: white;
  border-right: 1px solid #f2f2f2;
  width: 70px;
  padding-top: 10px;

  .el-scrollbar__wrap {
    max-height: calc(100vh - 68px);
    overflow-y: auto;
  }

  .menu-item {
    list-style: none;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    span {
      display: inline-flex;

      img {
        max-width: 27px;
        max-height: 27px;
      }
    }

    .menu-title {
      // display: none;
    }

    a {
      &.btn {
        background-color: transparent;
        padding: 0px;
        font-size: 2em;
        line-height: 1;
        transition: 0s all ease-in;
        color: #f754a2;
      }
    }
  }

  &.is-open {
    width: 220px;
    min-height: calc(100vh - 70px);

    .menu-item {
      display: flex;
      padding: 10px 15px;
      justify-content: flex-start;
      text-align: left;

      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.1em;

        &.btn {
          background-color: #f754a2;
          padding: 2px 10px;
          border-radius: 3px;

          .icon,
          .menu-title {
            color: #ffffff;
          }
        }
      }

      .menu-title {
        display: inline-flex;
        line-height: 30px;
        padding-left: 10px;
        color: #4c4b4e;
      }
    }
  }

  .menu-icon {
    width: 120px !important;
    height: 50px !important;
    visibility: initial !important;

    img {
      width: 30px;
    }
  }
}

.is-active {
  background: #ecf5ff;
}

.el-menu--collapse {
  .menu-icon {
    margin-right: 0 !important;
  }

  .el-tooltip {
    display: flex !important;
    align-items: center !important;
  }
}

.el-tooltip {
  padding: 0 0px !important;
}

.circle-icon-app {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  margin-top: 7px;
  margin-right: 5px;
}
</style>
